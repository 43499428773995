<template>
  <div>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('File Upload Successful')"
      @accept="goOrders"
      :active.sync="uploadSuccess"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span>{{ $t("File_Message_5") }}</span>
      </div>
    </vs-prompt>
    <vx-card>
      <div class="flex justify-between mb-4">
        <p class="fileName">{{ file.name }}</p>
        <p class="fileName text-center" style="min-width: 70px">
          {{ bytesToSize(file.size) }}
        </p>
      </div>
      <vs-progress
        class="mb-4"
        v-if="filePersent != 100 && filePersent != -1"
        :height="12"
        :percent="filePersent"
        :color="fileUploadFailed ? 'danger' : 'primary'"
      ></vs-progress>
      <vs-alert class="mb-2" :active="errorActive" color="danger">
        {{ errorMessage }}
      </vs-alert>
      <div class="flex items-center justify-between">
        <p v-if="!fileUploadSuccess"></p>
        <p v-if="fileUploadSuccess" style="color: green; font-weight: 600">
          <vs-icon icon="done" size="medium"></vs-icon>
          {{ $t("Upload_SuccessFull") }}
        </p>
        <vs-button
          v-if="!fileUploadSuccess"
          :disabled="fileUploadFailed || filePersent == 100"
          color="danger"
          @click="requestCancel()"
          >{{ $t("Cancel") }}</vs-button
        >
      </div>
    </vx-card>
  </div>
</template>

<script>
import API from "@/api/config";
import Api from "@/api/api";

import axios from "axios";

export default {
  data() {
    return {
      uploadSuccess: false,
      errorActive: false,
      errorMessage: "",
      filePersent: 0,
      request: axios.CancelToken.source(),
      fileUploadFailed: false,
      fileUploadSuccess: false,
      fileId: "",
    };
  },
  props: ["file", "id"],
  methods: {
    goOrders() {
      this.$router.push("/provider_orders");
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    uploadFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      this.request = axios.CancelToken.source();

      axios
        .request({
          method: "put",
          url: API.BASEURL + API.DENTAL_PROVIDER_ORDERS + this.id + "/upload/",
          data: formData,
          timeout: 1000000,
          cancelToken: this.request.token,
          headers: {
            authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            this.filePersent = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.fileUploadSuccess = true;
            this.uploadSuccess = true;
            this.fileId = response.data.id;
            this.$emit("uploadedFile");
          }
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message;
          this.errorActive = true;
          this.filePersent = -1;
          this.fileUploadFailed = true;
          this.$emit("FileError");
        });
    },
    requestCancel() {
      this.request.cancel("Cancel");
    },
  },
  created() {
    this.uploadFile();
  },
};
</script>

<style lang="scss">
.fileName {
  font-size: 1rem;
  font-weight: 600;
}
</style>