<template>
  <div>
    <vs-popup
      class="pdfClass"
      fullscreen
      :title="$t('Summary')"
      :active.sync="pdfEnable"
    >
      <vs-row vs-type="flex" vs-justify="center">
        <div
          ref="content"
          style="background-color: #fff; width: 720px; height: 1050px"
        >
          <!-- Sipariş Bilgisi  -->
          <div class="pdfBorder" style="display: inline-flex">
            <div class="pdfBorderRight" style="width: 130px">
              <p class="orderNoDateCreditPDF">
                {{ $t("Order") }} {{ $t("No_Number") }}:
                <span class="value"> {{ getOrder.id }} </span>
              </p>
            </div>
            <div class="pdfBorderRight" style="width: 110px">
              <p class="orderNoDateCreditPDF">
                {{ $t("Credit") }}:
                <span class="value"> {{ totalCredit() }} </span>
              </p>
            </div>
            <div class="pdfBorderRight" style="width: 300px">
              <p class="orderNoDateCreditPDF">
                {{ $t("Patient") }}:
                <span class="value"> {{ getOrder.patient }} </span>
              </p>
            </div>
            <div class="" style="width: 176px">
              <p class="orderNoDateCreditPDF">
                {{ $t("Date") }}:
                <span class="value">
                  {{ getRelativeTime(getOrder.created) }}
                </span>
              </p>
            </div>
          </div>

          <!-- Siparis özet -->
          <vs-row>
            <vs-col class="p-2 pdfBorderWithoutTop" vs-w="8">
              <div
                class="grid grid-cols-4"
                style="font-weight: 700; font-size: 1rem"
              >
                <div class="col-span-2">
                  {{ $t("Treatment") }} {{ $t("Type") }}
                </div>
                <div class="col-span-1">{{ $t("Quantity") }}</div>
                <div class="col-span-1">
                  <div class="flex justify-between content-center">
                    <p>{{ $t("Credit") }}</p>
                  </div>
                </div>
              </div>
              <vs-divider />
              <div
                class="grid grid-cols-4 mt-1"
                v-for="work in getDifferentWorks"
                :key="work.teeth"
              >
                <div class="col-span-2">
                  {{ work.name }}
                </div>
                <div class="col-span-1">
                  {{ work.unit }}
                </div>
                <div class="col-span-1">
                  {{ work.credit * work.unit }}
                </div>
              </div>

              <vs-divider />
              <div
                class="grid grid-cols-4"
                style="font-weight: 700; font-size: 1rem"
              >
                <div class="col-span-2">{{ $t("Total") }}</div>
                <div class="col-span-1">
                  {{ totalUnit() }}
                </div>
                <div class="col-span-1">
                  {{ totalCredit() }}
                </div>
              </div>
            </vs-col>

            <vs-col vs-w="4" class="pdfBorderWithoutTopLeft">
              <div class="positionRelative">
                <div>
                  <dentalSvg :info="true" ref="dentalSvgRefPdf" />
                </div>
                <div class="stackDiv flex items-center justify-center">
                  <vs-col style="width: fit-content">
                    <div
                      class="flex justify-start mb-2"
                      v-for="work in getDifferentWorks"
                      :key="work.code"
                    >
                      <vs-icon
                        size="10px"
                        :bg="work.color"
                        :color="work.color"
                      ></vs-icon>

                      <p class="ml-2" style="font-size: 0.6rem">
                        {{ work.name }}
                      </p>
                    </div>
                  </vs-col>
                </div>
              </div>
            </vs-col>
          </vs-row>

          <!-- 3D - Zirkon - İmplant Listeleri -->
          <vs-row style="height: 275px">
            <vs-col vs-w="3" class="p-2 pdfBorderWithoutTop">
              <vs-row class="w-full" vs-type="flex-wrap">
                <p class="text-center mb-2" style="font-weight: 700">
                  {{ $t("3D_Print") }}
                </p>
                <vs-col
                  vs-w="4"
                  v-for="teeth in get3DPrintsTeeth"
                  :key="teeth.teeth"
                >
                  <p class="text-center">{{ teeth.teeth }}</p>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col vs-w="3" class="p-2 pdfBorderWithoutTopLeft">
              <vs-row class="w-full" vs-type="flex-wrap">
                <p class="text-center mb-2" style="font-weight: 700">
                  {{ $t("Zirkon") }}
                </p>
                <vs-col
                  vs-w="4"
                  v-for="teeth in getZirkonTeeth"
                  :key="teeth.teeth"
                >
                  <p class="text-center">{{ teeth.teeth }}</p>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col vs-w="6" class="pdfBorderWithoutTopLeft">
              <p class="text-center mb-2" style="font-weight: 700">
                {{ $t("Implant") }}
              </p>
              <div
                class="pl-2 pr-2"
                :style="index % 2 == 0 ? '' : 'background-color: #ddd; '"
                style="display: flex"
                vs-type="flex-wrap"
                v-for="(brandModel, index) in getImplantBrandModel"
                :key="brandModel.brand + brandModel.model"
              >
                <vs-col vs-w="6">
                  <p>{{ $t("Brand") }} : {{ brandModel.brand }}</p>
                  <p>{{ $t("Model") }} : {{ brandModel.model }}</p>
                </vs-col>
                <vs-col vs-type="flex" vs-w="6">
                  <vs-row>
                    <vs-col
                      vs-w="4"
                      v-for="teeth in brandModel.teeth"
                      :key="teeth.teeth"
                    >
                      <p class="text-center">{{ teeth }}</p>
                    </vs-col>
                  </vs-row>
                </vs-col>
              </div>
            </vs-col>
          </vs-row>

          <!-- 3D - Zirkon- İmplant svgleri -->
          <vs-row>
            <vs-col vs-w="4" class="pdfBorderWithoutTop">
              <div class="positionRelative">
                <div>
                  <dentalSvg
                    :justShow="get3DPrintsTeeth"
                    ref="dentalSvgRef3D"
                  />
                </div>
                <div class="stackDiv flex items-center justify-center">
                  <vs-col style="width: fit-content">
                    <div
                      class="flex justify-start mb-1"
                      v-for="work in get3DPrintsTreatments"
                      :key="work.code"
                    >
                      <vs-icon
                        size="10px"
                        :bg="work.color"
                        :color="work.color"
                      ></vs-icon>

                      <p class="ml-2" style="font-size: 10px">
                        {{ work.name }}
                      </p>
                    </div>
                  </vs-col>
                </div>
              </div>
            </vs-col>
            <vs-col vs-w="4" class="pdfBorderWithoutTopLeft">
              <div class="positionRelative">
                <div>
                  <dentalSvg
                    :justShow="getZirkonTeeth"
                    ref="dentalSvgRefZirkon"
                  />
                </div>
                <div class="stackDiv flex items-center justify-center">
                  <vs-col style="width: fit-content">
                    <div
                      class="flex justify-start mb-1"
                      v-for="work in getZirkonTreatments"
                      :key="work.code"
                    >
                      <vs-icon
                        size="10px"
                        :bg="work.color"
                        :color="work.color"
                      ></vs-icon>

                      <p class="ml-2" style="font-size: 10px">
                        {{ work.name }}
                      </p>
                    </div>
                  </vs-col>
                </div>
              </div>
            </vs-col>
            <vs-col vs-w="4" class="pdfBorderWithoutTopLeft">
              <div class="positionRelative">
                <div>
                  <dentalSvg
                    :justShow="getImplantTeeth"
                    ref="dentalSvgRefImplant"
                  />
                </div>
                <div class="stackDiv flex items-center justify-center">
                  <vs-col style="width: fit-content">
                    <div
                      class="flex justify-start mb-1"
                      v-for="work in getImplantTreatments"
                      :key="work.code"
                    >
                      <vs-icon
                        size="10px"
                        :bg="work.color"
                        :color="work.color"
                      ></vs-icon>

                      <p class="ml-2" style="font-size: 10px">
                        {{ work.name }}
                      </p>
                    </div>
                  </vs-col>
                </div>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </vs-row>
    </vs-popup>
    <div class="detailsCols p-2 grid grid-cols-12">
      <div class="col-span-12 sm:col-span-12 lg:col-span-8 grid grid-cols-12">
        <div class="detailsCols col-span-12 sm:col-span-6 m-1 p-2">
          <vs-col class="detailTitles" vs-type="flex" vs-w="6">
            {{ $t("Order") }} {{ $t("No_Number") }} :
          </vs-col>
          <vs-col class="detailValues" vs-type="flex" vs-w="6">
            {{ order.id }}
          </vs-col>
          <vs-col class="detailTitles" vs-type="flex" vs-w="6">
            {{ $t("Owner") }} :
          </vs-col>
          <vs-col class="detailValues" vs-type="flex" vs-w="6">
            {{ order.owner }}
          </vs-col>
          <vs-col class="detailTitles" vs-type="flex" vs-w="6">
            {{ $t("Patient") }} :
          </vs-col>
          <vs-col class="detailValues" vs-type="flex" vs-w="6">
            {{ order.patient }}
          </vs-col>
          <vs-col class="detailTitles" vs-type="flex" vs-w="6">
            {{ $t("Credit") }} :
          </vs-col>
          <vs-col class="detailValues" vs-type="flex" vs-w="6">
            {{ order.credit }}
          </vs-col>
          <vs-col class="detailTitles" vs-type="flex" vs-w="6">
            {{ $t("Status") }} :
          </vs-col>
          <vs-col class="detailValues" vs-type="flex" vs-w="6">
            {{ getOrderStatus(order.order_status) }}
          </vs-col>
          <vs-col class="detailTitles" vs-type="flex" vs-w="6">
            {{ $t("Created Date") }} :
          </vs-col>
          <vs-col class="detailValues" vs-type="flex" vs-w="6">
            {{ getRelativeTime(order.created) }}
          </vs-col>
          <vs-col class="detailTitles" vs-type="flex" vs-w="12">
            {{ $t("Note") }} :
          </vs-col>
          <vs-col class="detailsCols p-2" vs-type="flex" vs-w="12">
            {{ order.note }}
          </vs-col>
          <VuePerfectScrollbar
            class="scroll-area"
            :settings="filesScrollSettings"
          >
            <vs-row
              class="p-2"
              vs-type="flex"
              vs-align="center"
              :key="file.id"
              v-for="file in orderFiles"
            >
              <downloadFile
                ref="downloadFile"
                class="mt-2"
                :file="file"
                :id="orderId"
                :historyMode="true"
                @downloaded="order.order_status = 'P'"
              />
            </vs-row>
          </VuePerfectScrollbar>
          <vs-row class="p-2" vs-type="flex" vs-justify="flex-end">
            <vs-button @click="downloadAllFiles">
              {{ $t("Download All") }}
            </vs-button>
          </vs-row>
        </div>
        <div class="detailsCols col-span-12 sm:col-span-6 m-1 p-2">
          <div v-if="get3DPrintsTeeth.length" class="detailTitles -mb-1">
            {{ $t("3D_Print") }}
          </div>
          <div class="flex flex-wrap">
            <div
              style="display: contents"
              class="detailValues"
              :key="teeth.teeth"
              v-for="(teeth, index) in get3DPrintsTeeth"
            >
              {{ teeth.teeth }}

              <p class="mr-2" v-if="get3DPrintsTeeth[index + 1] != null">,</p>
            </div>
          </div>
          <div v-if="getZirkonTeeth.length" class="detailTitles mt-2 -mb-1">
            {{ $t("Zirkon") }}
          </div>
          <div class="flex flex-wrap">
            <div
              style="display: contents"
              class="detailValues"
              :key="teeth.teeth"
              v-for="(teeth, index) in getZirkonTeeth"
            >
              {{ teeth.teeth }}

              <p class="mr-2" v-if="getZirkonTeeth[index + 1] != null">,</p>
            </div>
          </div>
          <div v-if="getImplantTeeth.length" class="detailTitles mt-2 -mb-1">
            {{ $t("Implant") }}
          </div>
          <div class="flex flex-wrap">
            <div
              style="display: contents"
              class="detailValues"
              :key="teeth.teeth"
              v-for="(teeth, index) in getImplantTeeth"
            >
              {{ teeth.teeth }}

              <p class="mr-2" v-if="getImplantTeeth[index + 1] != null">,</p>
            </div>
          </div>

          <div class="mt-4">
            <vs-button target :href="{ url: order.plate_file }">{{
              $t("Download")
            }}</vs-button>
          </div>
        </div>
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-4 detailsCols m-1">
        <div style="position: relative">
          <div>
            <dentalSvg class="w-full" :info="true" ref="dentalSvgRef" />
          </div>
          <div class="stackDiv flex items-center justify-center">
            <vs-col style="width: fit-content">
              <div
                class="flex justify-start mb-2"
                v-for="work in getDifferentWorks"
                :key="work.code"
              >
                <vs-icon
                  size="20px"
                  :bg="work.color"
                  :color="work.color"
                ></vs-icon>

                <p class="ml-2">{{ work.name }}</p>
              </div>
            </vs-col>
          </div>
        </div>
      </div>
    </div>
    <div class="p-2">
      <vs-button @click="exportPDF"> {{ $t("Export") }} </vs-button>
    </div>
  </div>
</template>

<script>
import API from "@/api/config";
import Api from "@/api/api";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";

import dentalSvg from "../dental/components/DentalSvg.vue";
import downloadFile from "./components/DownloadFile";
import uploadFile from "./components/File";

export default {
  data() {
    return {
      delayDoImage: 500,
      pdfEnable: false,
      files: null,
      fileUploadEnable: true,
      filesScrollSettings: {
        maxScrollbarLength: 100,
        wheelSpeed: 0.6
      },
      orderId: null,
      order: {
        id: null,
        owner: null
      },
      orderFiles: [],
      orderItems: []
    };
  },
  computed: {
    getOrder() {
      return this.$store.getters["dental/getDentalOrder"];
    },
    get3DPrintsTeeth() {
      return this.$store.getters["dental/get3DPrintsTeeth"];
    },
    getZirkonTeeth() {
      return this.$store.getters["dental/getZirkonTeeth"];
    },
    getImplantTeeth() {
      return this.$store.getters["dental/getImplantTeeth"];
    },
    getDifferentWorks() {
      return this.$store.getters["dental/getDifferentWorks"];
    },
    getImplantBrandModel() {
      return this.$store.getters["dental/getImplantBrandModel"];
    },
    get3DPrintsTreatments() {
      return this.$store.getters["dental/get3DPrintsTreatments"];
    },
    getZirkonTreatments() {
      return this.$store.getters["dental/getZirkonTreatments"];
    },
    getImplantTreatments() {
      return this.$store.getters["dental/getImplantTreatments"];
    }
  },
  components: {
    dentalSvg,
    VuePerfectScrollbar,
    downloadFile,
    uploadFile
  },
  methods: {
    exportPDF() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      this.pdfEnable = true;
      var that = this;

      setTimeout(() => {
        domtoimage
          .toPng(this.$refs.content)
          .then(function(dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            const doc = new jsPDF({
              orientation: "portrait"
            });
            doc.addImage(img, "JPEG", 10, 10);
            const date = new Date();
            const filename = that.getOrder.id + ".pdf";
            doc.save(filename);
            that.pdfDownloaded();
          })
          .catch(function(error) {
            that.$vs.loading.close();
            that.exportPDF();
          });
      }, this.delayDoImage);
    },
    pdfDownloaded() {
      this.pdfEnable = false;
      this.$vs.loading.close();
    },
    downloadAllFiles() {
      this.$refs.downloadFile.forEach(element => {
        element.downloadFile();
      });
    },
    getOrderStatus(status) {
      if (status == "P") return this.$t("Processing");
      if (status == "R") return this.$t("Ready");
      if (status == "C") return this.$t("Cancelled");
      if (status == "W") return this.$t("Waiting_Payment");
      return this.$t("New");
    },
    getRelativeTime(value) {
      return moment(value).format("DD/MM/YYYY - HH:mm");
    },
    addFilesTheOrder() {
      this.fileUploadEnable = true;
      setTimeout(() => {
        this.$router.push("/provider_orders");
      }, 500);
    },
    handleFileUpload() {
      this.files = this.$refs.files.files;
      this.fileUploadEnable = false;
    },
    getOrderDetail(id) {
      Api.get(
        API.BASEURL + API.DENTAL_PROVIDER_ORDERS_REPORT + id + "/",
        this.handleGetOrderDetail
      );
    },
    handleGetOrderDetail(status, data) {
      this.$vs.loading.close();
      if (status >= 200 && status < 300) {
        this.order = data;
        this.orderFiles = data.order_files;
        this.orderItems = data.order_items;
        this.$store.dispatch("dental/cleanWork");
        this.$store.dispatch("dental/changeOrder", data);
        this.$refs.dentalSvgRef.initOrder(false);
        this.$refs.dentalSvgRef.initPage();
        this.$refs.dentalSvgRef.initDots();
      }
    },
    initPage() {
      if (typeof this.$route.query.id != "undefined") {
        this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
        let id = this.$route.query.id;
        this.orderId = id;
        this.getOrderDetail(id);
      }
    },
    totalUnit() {
      var total = 0;

      this.getDifferentWorks.forEach(element => {
        total += element.unit;
      });

      return total;
    },
    totalCredit() {
      var total = 0;

      this.getDifferentWorks.forEach(element => {
        total += element.credit * element.unit;
      });

      return total;
    }
  },
  destroyed() {
    this.$store.dispatch("dental/removeDentalOrder");
  },

  created() {
    this.initPage();
  }
};
</script>

<style lang="scss" scoped>
.inputButton {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputButton + label {
  color: rgba(var(--vs-primary), 1);
  border: 1px solid rgba(var(--vs-primary), 1);
  background: transparent !important;
  display: inline-block;
  padding: 0.65rem 1rem;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
}
.inputButtonDisabled {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputButtonDisabled + label {
  color: rgba(var(--vs-primary), 1);
  border: 1px solid rgba(var(--vs-primary), 1);
  background: transparent !important;
  opacity: 0.5;
  display: inline-block;
  padding: 0.65rem 1rem;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
}
.scroll-area {
  position: relative;
  width: auto;
  height: fit-content;
  max-height: 300px;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.detailTitles {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #c89e82;
}
.detailValues {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #555555;
}
.detailsCols {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.stackDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.pdfClass {
  .orderNoDateCreditPDF .value {
    color: #000;
  }
  .grid {
    color: #000;
  }
  .vs-row {
    color: #000;
  }
}
.pdfBorder {
  border: 2px solid #aaa;
}
.pdfBorderRight {
  border-right: 2px solid #aaa;
}
.pdfBorderWithoutTop {
  border-left: 2px solid #aaa;
  border-bottom: 2px solid #aaa;
  border-right: 2px solid #aaa;
}
.pdfBorderWithoutTopLeft {
  border-bottom: 2px solid #aaa;
  border-right: 2px solid #aaa;
}
.positionRelative {
  position: relative;
}
.orderNoDateCredit {
  margin-top: 1rem;
  font-size: 18px;
  font-weight: 600;
  color: #c89e82;
  .value {
    color: #555555;
  }
}
.orderNoDateCreditPDF {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  font-size: 14px;
  font-weight: 600;
  color: #c89e82;
  .value {
    color: #555555;
  }
}
</style>
