<template>
  <div class="w-full">
    <div class="flex justify-between items-center w-full">
      <div class="flex justify-between items-center">
        <vs-icon
          class="float-left"
          icon="attach_file"
          size="medium"
          color="primary"
        />
        <p>{{ file.file_name }}</p>
      </div>
      <vs-button
        :icon="downloadIcon"
        :color="downloadButtonColor"
        @click="downloadFile()"
      >
      </vs-button>
    </div>
    <vs-progress
      class="mt-2"
      v-if="filePersent && filePersent != 100"
      :height="12"
      :percent="filePersent"
    ></vs-progress>
  </div>
</template>

<script>
import axios from "axios";

import Api from "@/api/api";
import API from "@/api/config";

export default {
  data() {
    return {
      request: axios.CancelToken.source(),
      filePersent: 0,
      downloadIcon: "get_app",
      downloadButtonColor: "primary",
    };
  },
  props: ["file", "id", "historyMode"],
  methods: {
    downloadFile() {
      if (this.historyMode != null && this.historyMode == true) {
        this.downloadHistoryMode();
        return;
      }

      if (this.downloadButtonColor == "danger") {
        this.requestCancel();
        return;
      }
      this.downloadIcon = "clear";
      this.downloadButtonColor = "danger";

      Api.get(
        API.BASEURL + API.DENTAL_PROVIDER_ORDERS + this.id + "/files/",
        this.handleDownloadOrderFile
      );
    },
    downloadHistoryMode() {
      this.request = axios.CancelToken.source();
      var downloadFileName = this.file.file_name;

      axios({
        url: this.file.file,
        method: "GET",
        cancelToken: this.request.token,
        responseType: "blob",
        onDownloadProgress: function (progressEvent) {
          this.filePersent = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then((response) => {
          this.downloadIcon = "get_app";
          this.downloadButtonColor = "primary";
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", downloadFileName);
          document.body.appendChild(link);
          link.click();
          this.$emit("downloaded");
        })
        .catch((error) => {
          if (error.message == "Cancel") {
            this.filePersent = 0;
            this.downloadIcon = "get_app";
            this.downloadButtonColor = "primary";
          }
        });
    },
    handleDownloadOrderFile(status, data) {
      if (status == 200) {
        const index = data.findIndex((i) => i.id == this.file.id);

        if (index != -1) {
          if (data[index].file != "") {
            this.request = axios.CancelToken.source();
            var downloadFileName = data[index].file_name;

            axios({
              url: data[index].file,
              method: "GET",
              cancelToken: this.request.token,
              responseType: "blob",
              onDownloadProgress: function (progressEvent) {
                this.filePersent = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
              }.bind(this),
            })
              .then((response) => {
                this.downloadIcon = "get_app";
                this.downloadButtonColor = "primary";
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", downloadFileName);
                document.body.appendChild(link);
                link.click();
                this.$emit("downloaded");
              })
              .catch((error) => {
                if (error.message == "Cancel") {
                  this.filePersent = 0;
                  this.downloadIcon = "get_app";
                  this.downloadButtonColor = "primary";
                }
              });
          } else {
            this.downloadIcon = "get_app";
            this.downloadButtonColor = "primary";
            this.$vs.notify({
              title: this.$t("Warning"),
              text: this.$t("File_Message_1"),
              color: "danger",
            });
          }
        } else {
          this.downloadIcon = "get_app";
          this.downloadButtonColor = "primary";
          this.$vs.notify({
            title: this.$t("Warning"),
            text: this.$t("File_Message_1"),
            color: "danger",
          });
        }
      } else if (status == 404) {
        this.downloadIcon = "get_app";
        this.downloadButtonColor = "primary";
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("File_Message_1"),
          color: "danger",
        });
      } else if (status == 500) {
        this.downloadIcon = "get_app";
        this.downloadButtonColor = "primary";
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("File_Message_1"),
          color: "danger",
        });
      }
    },
    requestCancel() {
      this.request.cancel("Cancel");
      this.downloadIcon = "get_app";
      this.downloadButtonColor = "primary";
    },
  },
};
</script>

<style>
</style>